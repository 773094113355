import { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react';
import styled from 'styled-components';

const RowFC: ForwardRefRenderFunction<any, HTMLAttributes<any>> = (
  props,
  ref
) => {
  return (
    <StyledRow
      ref={ref}
      {...props}
      className={`row ${props.className ?? ''}`}
    />
  );
};

export const Row = forwardRef(RowFC);

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
