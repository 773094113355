//@ts-nocheck
export const Scripts = () => {
  const nonce = process.env.NEXT_PUBLIC_NONCE as string;

  return (
    <>
      <script
        nonce={nonce}
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-XBJWHD5Z9R"
      />
      <script
        nonce={nonce}
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-10978453293"
      />
      <script nonce={nonce} src="/js/gtm.js" />
      <script
        nonce={nonce}
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js.hs-scripts.com/22297332.js"
      />

      <script
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: `
        _linkedin_partner_id = "3501468"; 
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; 
        window._linkedin_data_partner_ids.push(_linkedin_partner_id); 
      `,
        }}
      />

      <script
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: `
          (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; 
          window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; 
          var b = document.createElement("script"); b.type = "text/javascript";b.async = true; 
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);
      `,
        }}
      />

      <noscript nonce={nonce}>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=3501468&fmt=gif"
        />
      </noscript>

      <script
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1280785792734144');
  fbq('track', 'PageView');`,
        }}
      />
      <noscript nonce={nonce}>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=1280785792734144&ev=PageView&noscript=1"
        />
      </noscript>
      <script
        nonce={nonce}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          _linkedin_partner_id = "3501468"; 
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; 
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `,
        }}
      />
      <script
        nonce={nonce}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; 
          window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; 
          var b = document.createElement("script"); 
          b.type = "text/javascript";b.async = true; 
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; 
          s.parentNode.insertBefore(b, s);})(window.lintrk);
        `,
        }}
      />
      <noscript nonce={nonce}>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=3501468&fmt=gif"
        />
      </noscript>

      <script
        nonce={nonce}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          function initApollo(){var n=Math.random().toString(36).substring(7),o=document.createElement("script");
          o.src="https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache="+n,o.async=!0,o.defer=!0,
          o.onload=function(){window.trackingFunctions.onLoad({appId:"66185ade46511d01c73477f0"})},
          document.head.appendChild(o)}initApollo();
        `,
        }}
      />
    </>
  );
};

export const GTMConversions = {
  signup: () => {
    (window as any)?.gtag?.('event', 'conversion', {
      send_to: 'AW-10978453293/m5zuCOyVtZkYEK3O9_Io',
    });
  },

  contact: () => {
    (window as any)?.gtag?.('event', 'conversion', {
      send_to: 'AW-10978453293/s5s0COmVtZkYEK3O9_Io',
    });
  },
};
