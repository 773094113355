import { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react';
import styled from 'styled-components';

const ResponsiveContainerFC: ForwardRefRenderFunction<
  any,
  HTMLAttributes<any>
> = (props, ref) => {
  return <StyledContainer ref={ref} {...props} />;
};

export const ResponsiveContainer = forwardRef(ResponsiveContainerFC);

const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 720px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 960px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 1140px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 1380px;
  }
`;
