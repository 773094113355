import { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react';
import styled from 'styled-components';

const ColComponent: ForwardRefRenderFunction<
  any,
  HTMLAttributes<any> & IColProps
> = (props, ref) => {
  return <StyledCol ref={ref} {...props} />;
};

export const Col = forwardRef(ColComponent);

function getWidth(span: number) {
  const width = (span / 12) * 100;
  return `width: ${width}%;`;
}

const StyledCol = styled.div<IColProps>`
  ${({ xs }) => (xs ? getWidth(xs) : 'width: 100%;')}

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    ${({ sm }) => sm && getWidth(sm)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    ${({ md }) => md && getWidth(md)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    ${({ lg }) => lg && getWidth(lg)}
  }
`;

interface IColProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}
