import { DefaultTheme } from 'styled-components';
import { getTheme } from '../theme/getTheme';

const breakpoints = getTheme().breakpoints;

export function mediaScreen(min?: TBreakpoint, max?: TBreakpoint) {
  const minWidth = !min ? 0 : breakpoints[min];
  const maxWidth = !max ? 0 : breakpoints[max];

  if (minWidth && !maxWidth) {
    return `
      @media (min-width: ${minWidth}px)
    `;
  }

  if (!minWidth && maxWidth) {
    return `
      @media (max-width: ${maxWidth}px)
    `;
  }

  if (minWidth && maxWidth) {
    return `
      @media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)
    `;
  }

  return ``;
}

type TBreakpoint = keyof DefaultTheme['breakpoints'];
