import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export function useThrottledScroll(callback: TCallback) {
  const [, setScrollPosition] = useState(0);

  let previousScrollTop = 0;

  function handleDocumentScroll(e: any) {
    const currentScrollTop = e?.target?.documentElement?.scrollTop ?? 0;

    setScrollPosition((previousPosition) => {
      previousScrollTop = previousPosition;
      return currentScrollTop;
    });

    callback({ previousScrollTop, currentScrollTop });
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250);

  useEffect(() => {
    window.document.addEventListener('scroll', handleDocumentScrollThrottled);

    return () =>
      window.document.removeEventListener(
        'scroll',
        handleDocumentScrollThrottled
      );
  }, []);
}

type TCallback = (data: {
  previousScrollTop: number;
  currentScrollTop: number;
}) => void;
