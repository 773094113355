import { capitalize, cloneDeep, orderBy } from 'lodash';
import { useRouter } from 'next/dist/client/router';
import Head from 'next/head';
import Link from 'next/link';
import {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useThrottledScroll } from '../../hooks/useThrottledScroll';
import {
  HeaderLinks,
  Header as HeaderType,
  Maybe,
} from '../../types/contentstack';
import { mediaScreen } from '../../utils/mediaScreen';
import { InfoStrip } from '../info-strip';
import { ResponsiveContainer } from '../responsive';
import { GTMConversions } from '../scripts';
import { Button } from '../ui/button';
import { Icon } from '../ui/icon';

export const Header: FunctionComponent<IHeader> = ({
  pageTitle,
  pageDescription,
  links,
  info_strip,
  disableHiding = true,
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 100;

  useThrottledScroll(({ previousScrollTop, currentScrollTop }) => {
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setHasShadow(currentScrollTop > 2);

    if (disableHiding) {
      return;
    }

    setTimeout(() => {
      setIsHidden(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <title>{`Mapped | ${pageTitle}`}</title>
        <meta
          name="description"
          content={pageDescription || 'A new perspective on IoT.'}
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Mapped | ${pageTitle}`} />
        <meta
          property="og:description"
          content={pageDescription || 'A new perspective on IoT.'}
        />
        <meta
          property="og:image"
          content="https://www.mapped.com/img/og-image.png"
        />
        <meta
          name="facebook-domain-verification"
          content="fznnmgxih7yiannqntpwylu8jhh8mi"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={`Mapped | ${pageTitle}`} />
        <meta
          property="twitter:description"
          content={pageDescription || 'A new perspective on IoT.'}
        />
        <meta
          property="twitter:image"
          content="https://www.mapped.com/img/og-image.png"
        />
      </Head>

      <DesktopHeader
        isHidden={isHidden}
        hasShadow={hasShadow}
        links={links}
        info_strip={info_strip}
      />
      <MobileHeader
        isHidden={isHidden}
        hasShadow={hasShadow}
        links={links}
        info_strip={info_strip}
      />
    </>
  );
};

const DesktopHeader: FunctionComponent<IPlatformHeaderProps> = ({
  isHidden,
  hasShadow,
  links,
  info_strip,
}) => {
  const { pathname, push } = useRouter();

  const [openLinkMenu, setOpenLinkMenu] = useState<string>('');

  function isActive(path?: string | null, strict?: boolean) {
    if (strict) {
      return pathname === path;
    }

    return pathname.includes(path ?? '');
  }

  function openMenu(key?: string | null) {
    setOpenLinkMenu(key || '');
  }

  function closeMenu() {
    setOpenLinkMenu('');
  }

  return (
    <Container
      className="desktop-only"
      hasShadow={hasShadow}
      isHidden={isHidden}
    >
      <HeaderInfoStrip {...info_strip} />

      <Content>
        <NextLink href="/">
          <Logo
            width="160"
            height="50"
            src="/img/header-logo.svg"
            alt="Mapped Logo"
          />
        </NextLink>

        <Nav>
          {links?.map((link, idx) => (
            <LinkRenderer
              key={link?.title + idx.toString()}
              link={link}
              isActive={isActive}
              isHidden={isHidden}
              openLinkMenu={openLinkMenu}
              openMenu={openMenu}
              closeMenu={closeMenu}
              push={push as any}
            />
          ))}
        </Nav>
      </Content>
    </Container>
  );
};

const MobileHeader: FunctionComponent<IPlatformHeaderProps> = ({
  isHidden,
  hasShadow,
  links,
  info_strip,
}) => {
  const { pathname, push, asPath } = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const [openLinkMenu, setOpenLinkMenu] = useState<string>('');

  function isActive(path?: string | null, strict?: boolean) {
    if (strict) {
      return pathname === path;
    }

    return pathname.includes(path ?? '');
  }

  function openMenu(key?: string | null) {
    setOpenLinkMenu(key || '');
  }

  function closeMenu() {
    setOpenLinkMenu('');
  }

  useEffect(() => {
    setIsOpen(false);
  }, [asPath]);

  return (
    <>
      {isOpen && <DisableScroll />}

      <Container
        className="mobile-only"
        isOpen={isOpen}
        isHidden={isHidden}
        hasShadow={hasShadow}
      >
        <HeaderInfoStrip {...info_strip} />

        <Content>
          <NextLink href="/">
            <Logo
              src="/img/header-logo.svg"
              width="160"
              height="50"
              alt="Mapped Logo"
            />
          </NextLink>

          <Nav>
            <Toggle
              src={isOpen ? `/img/close-icon.svg` : `/img/menu-toggle.svg`}
              width="24"
              height="24"
              alt="menu toggle"
              onClick={() => setIsOpen(!isOpen)}
            />
          </Nav>
        </Content>

        {isOpen && (
          <MobileLinks>
            {links?.map((link, idx) => (
              <LinkRenderer
                key={link?.title + idx.toString()}
                link={link}
                isMobile={true}
                isActive={isActive}
                isHidden={isHidden}
                openLinkMenu={openLinkMenu}
                openMenu={openMenu}
                closeMenu={closeMenu}
                push={push as any}
              />
            ))}
          </MobileLinks>
        )}
      </Container>
    </>
  );
};

const DropdownMenu: FunctionComponent<{ onClose: () => void }> = ({
  children,
  onClose,
}) => {
  useLayoutEffect(() => {
    setTimeout(() => {
      document.body.addEventListener('click', onClose);
    }, 100);

    return () => document.body.removeEventListener('click', onClose);
  }, []);

  return <div className="dropdown-menu">{children}</div>;
};

const LinkRenderer: FunctionComponent<ILinkRenderer> = (props) => {
  const {
    openLinkMenu,
    isActive,
    isHidden,
    isMobile,
    openMenu,
    closeMenu,
    push,
    link,
  } = props;

  const {
    title,
    href,
    is_call_to_action,
    sub_links,
    ref_sub_linksConnection,
    mobile_only,
  } = link ?? {};

  const isMenuOpen = openLinkMenu === href;

  const subLinks = useMemo(() => {
    const links = cloneDeep(sub_links || []);

    const orderedRefSubLinks = orderBy(
      ref_sub_linksConnection?.edges,
      'node.position'
    );

    orderedRefSubLinks?.forEach((edge) => {
      if (edge?.node?.home_hero_only) {
        return;
      }

      links.push({
        title: edge?.node?.short_title,
        href: edge?.node?.url,
      });
    });

    return links;
  }, [sub_links, ref_sub_linksConnection]);

  switch (true) {
    case !!mobile_only:
      if (!isMobile) {
        return null;
      }

      if (href?.startsWith('http')) {
        return (
          <a href={href} key={href} target="_blank">
            <SubNavLink>{title}</SubNavLink>
          </a>
        );
      }

      return (
        <NextLink href={href}>
          <SubNavLink>{title}</SubNavLink>
        </NextLink>
      );

    case !!is_call_to_action:
      if (isMobile) {
        if (openLinkMenu) {
          return null;
        }

        return (
          <Button
            mColor="teal"
            onClick={() => {
              if (title?.toLowerCase() === 'sign up') {
                GTMConversions.signup();
              }

              push(href);
            }}
          >
            {title}
          </Button>
        );
      }

      return (
        <CallToAction
          onClick={() => {
            if (title?.toLowerCase() === 'sign up') {
              GTMConversions.signup();
            }

            push(href);
          }}
        >
          {title}
        </CallToAction>
      );

    case !!subLinks?.length:
      return (
        <NavLink
          isActive={isMenuOpen || isActive(href)}
          onClick={() => (!isMobile ? null : openMenu(href))}
          onMouseEnter={() => (isMobile ? null : openMenu(href))}
          onMouseLeave={() => (isMobile ? null : closeMenu())}
        >
          <span style={{ display: 'flex' }}>
            {title}
            <Icon
              name="arrow-down"
              style={{ marginRight: -7, marginLeft: 5 }}
            />
          </span>

          {isMenuOpen && !isHidden && (
            <DropdownMenu onClose={() => closeMenu()}>
              {subLinks?.map((subLink) => (
                <NextLink href={subLink?.href} key={subLink?.href}>
                  <span
                    className={
                      isActive(subLink?.href, true) ? 'link active' : 'link'
                    }
                  >
                    {capitalize(subLink?.title!)}
                  </span>
                </NextLink>
              ))}
            </DropdownMenu>
          )}
        </NavLink>
      );

    default:
      return (
        <NextLink href={href}>
          <NavLink isActive={isActive(href)}>{title}</NavLink>
        </NextLink>
      );
  }
};

const HeaderInfoStrip: FunctionComponent<HeaderType['info_strip']> = ({
  title,
  href,
}) => {
  if (!title) {
    return null;
  }

  return (
    <InfoStrip
      style={{ margin: '-16px 0 16px 0' }}
      onClick={() => !!href && window.open(href, '_blank')}
    >
      {title}
    </InfoStrip>
  );
};

export const HEADER_QUERY = `
  header(uid: "blt5fb9062629d0265f") {
    info_strip {
      title
      href
    }
    links {
      title
      href
      is_call_to_action
      mobile_only
      ref_sub_linksConnection {
        edges {
					node {
						...on UseCase {
							short_title
              home_hero_only
              position
							url
						}
					}
				}
      }
      sub_links {
        title
        href
      }
    }
  }
`;

const NextLink = Link as any;

const DisableScroll = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`;

const Container = styled.header<{
  isOpen?: boolean;
  isHidden: boolean;
  hasShadow: boolean;
}>`
  width: 100%;
  padding: 16px 0;
  z-index: 100;
  position: fixed;
  top: 0;
  background: ${(props) => props.theme.colors.slate1};
  transform: translateY(0);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  ${({ isHidden }) =>
    isHidden &&
    `
  transform: translateY(-110%);
  `}

  ${({ hasShadow }) =>
    hasShadow &&
    `
    box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.23);
  `}

  &.mobile-only {
    display: none;
  }

  ${mediaScreen('xs', 'md')} {
    &.desktop-only {
      display: none;
    }

    &.mobile-only {
      display: block;
    }
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    `
    z-index: 100;
    background: ${theme.colors.slate1};
    transform: translateY(0) !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  `}
`;

const Content = styled(ResponsiveContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 160px;
  cursor: pointer;
  pointer-events: all;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NavLink = styled.div<{
  isActive?: boolean;
  isDropdownMenuOpen?: boolean;
}>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 20px;
  position: relative;
  top: 4px;
  border-bottom: 2px solid transparent;
  padding-bottom: 8px;
  cursor: pointer;
  outline: none;

  &,
  &:visited {
    color: white;
  }

  &:hover {
    border-color: white;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    border-color: ${theme.colors.teal} !important;
  `}

  &:last-of-type {
    margin-right: 0;
  }

  .dropdown-menu {
    background: ${(props) => props.theme.colors.slate2};
    position: absolute;
    top: 34px;
    left: 0;
    padding: 7px 0;

    .link {
      font-size: 16px;
      color: white;
      padding: 7px 15px;
      cursor: pointer;
      white-space: nowrap;
      display: block;
      font-weight: 100;

      &:hover {
        background: rgba(255, 255, 255, 0.02);
      }

      &.active {
        font-weight: bold;
        background: rgba(255, 255, 255, 0.02);
      }
    }
  }
`;

const CallToAction = styled(NavLink)`
  top: 0;
  font-size: 14px;
  border: 2px solid ${({ theme }) => theme.colors.teal};
  padding: 13px 24px 12px 24px;
  text-transform: uppercase;
  line-height: 16px;
  font-weight: bold;

  &,
  &:visited {
    color: ${({ theme }) => theme.colors.teal};
  }

  &:hover {
    color: white;
    border-color: white;
  }
`;

const Toggle = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  pointer-events: all;
`;

const SubNavLink = styled.span`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  font-weight: bold;
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
`;

const MobileLinks = styled.div`
  height: calc(100% - 81px);
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding: 0 32px;
  padding-bottom: 32px;
  position: relative;

  ${NavLink} {
    font-size: 29px;
    line-height: 32px;
    margin-bottom: 24px;
    margin-left: 0;
    border: none;

    span {
      align-items: center;
    }

    &:hover {
      opacity: 1;
    }
  }

  ${SubNavLink} {
    &:first-of-type {
      margin-top: 20px;
    }
  }

  button {
    position: absolute;
    bottom: 32px;
    left: 32px;
    width: calc(100% - 64px);
  }

  .dropdown-menu {
    background: none;
    position: relative;
    top: 0;
    margin-top: 20px;

    .link {
      font-size: 16px;
      color: white;
      padding: 7px 25px;
      cursor: pointer;
      white-space: nowrap;
      display: block;
      font-weight: 100;
      background: none !important;
    }
  }
`;

interface IHeader {
  pageTitle?: string | null;
  pageDescription?: string | null;
  links?: Maybe<HeaderLinks>[] | null;
  info_strip?: HeaderType['info_strip'];
  disableHiding?: boolean;
}

interface IPlatformHeaderProps {
  isHidden: boolean;
  hasShadow: boolean;
  links?: Maybe<HeaderLinks>[] | null;
  info_strip?: HeaderType['info_strip'];
}

interface ILinkRenderer {
  link: Maybe<HeaderLinks>;
  openLinkMenu: string;
  isActive: (path?: string | null, strict?: boolean) => boolean;
  isHidden?: boolean;
  isMobile?: boolean;
  openMenu: (key?: string | null) => void;
  closeMenu: () => void;
  push: (path?: string | null) => void;
}
