import { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

const ForwardableIcon: ForwardRefRenderFunction<
  any,
  HTMLAttributes<HTMLDivElement> & IIconProps
> = ({ name, size = 24, color, className = '', forceColor, ...props }, ref) => {
  return (
    <Wrapper
      className={`icon icon-${name} ${className}`}
      ref={ref}
      size={size}
      color={color}
      forceColor={forceColor}
      {...props}
    >
      <SVG src={`/icons/${name}.svg`} cacheRequests={true} />
    </Wrapper>
  );
};

export const Icon = forwardRef(ForwardableIcon);

const Wrapper = styled.div<{
  size: number;
  color?: string;
  forceColor?: boolean;
}>`
  display: block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  ${({ color }) =>
    color &&
    `
      color: ${color};
    `}

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;

    ${({ forceColor }) =>
      forceColor &&
      `
    fill: currentColor !important;
    `}
  }
`;

export type TIconName =
  | 'checkmark'
  | 'warning'
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-right'
  | 'arrow-left'
  | 'external-link'
  | 'pdf'
  | 'lock'
  | 'close'
  | 'info-circle';

export interface IIconProps {
  name: TIconName;
  size?: number;
  color?: string;
  forceColor?: boolean;
}
