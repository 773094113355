import {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import styled, { DefaultTheme } from 'styled-components';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { getTheme } from '../../theme/getTheme';
import { mediaScreen } from '../../utils/mediaScreen';

const ButtonFC: ForwardRefRenderFunction<
  any,
  ButtonHTMLAttributes<any> & IButton
> = (
  {
    mType = 'primary',
    mSize = 'large',
    mColor = 'eb1',
    disabled,
    children,
    loading,
    ...props
  },
  ref
) => {
  const theme = getTheme();

  return (
    <StyledButton
      mType={mType}
      mColor={mColor}
      mSize={mSize}
      disabled={disabled || loading}
      {...props}
      ref={ref}
    >
      {!loading ? (
        children
      ) : (
        <Loader top={mSize === 'large' ? -8 : -9} className="loader">
          <PropagateLoader
            size={10}
            color={mType === 'secondary' ? theme.colors[mColor] : 'white'}
          />
        </Loader>
      )}
    </StyledButton>
  );
};

export const Button = forwardRef(ButtonFC);

export interface IButton {
  mType?: 'primary' | 'secondary';
  mSize?: 'large' | 'medium';
  disabled?: boolean;
  mColor?: keyof DefaultTheme['colors'];
  loading?: boolean;
}

const StyledButton = styled.button<IButton>`
  appearance: none;
  font-weight: 500;
  color: white;
  cursor: pointer;
  outline: none !important;
  background-color: ${({ theme, mColor }) => mColor && theme.colors[mColor]};
  border: 1px solid ${({ theme, mColor }) => mColor && theme.colors[mColor]};
  user-select: none;
  text-transform: uppercase;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.4;  
  `}

  ${({ mSize }) => {
    switch (mSize) {
      case 'medium':
        return `
          padding: 8px 16px;
          font-size: 14px;
          height: 34px;
        `;

      case 'large':
        return `
          padding: 12px 24px;
          font-size: 16px;
          height: 48px;
        `;

      default:
        return '';
    }
  }}

  ${mediaScreen('xs', 'sm')} {
    padding: 8px 16px;
    font-size: 14px;
    height: 46px;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.8;
  }

  ${({ theme, mColor, mType }) =>
    mType === 'secondary' &&
    `
      background-color: transparent;
      color: ${mColor && theme.colors[mColor]}
    `}
`;

const Loader = styled.div<{ top?: number }>`
  width: 100%;
  height: 100%;

  > span {
    display: inline-block;
    top: ${(props) => props.top}px;
  }
`;
