import { FunctionComponent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled, { CSSProperties } from 'styled-components';
import { mediaScreen } from '../../utils/mediaScreen';
import { Icon } from '../ui/icon';

export const InfoStrip: FunctionComponent<IInfoStrip> = ({
  children,
  onClick,
  style,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [cookies, setCookie] = useCookies([COOKIE_KEY]);

  function isDismissed() {
    return cookies[COOKIE_KEY] === 'dismissed';
  }

  function dismiss() {
    setCookie(COOKIE_KEY, 'dismissed');
  }

  useEffect(() => {
    setIsVisible(!isDismissed());
  }, [isDismissed()]);

  if (!isVisible) {
    return null;
  }

  return (
    <Container
      id="info-strip"
      style={style}
      onClick={() => {
        dismiss();
        onClick();
      }}
    >
      <span>{children}</span>

      <Icon
        name="close"
        size={14}
        onClick={(e) => {
          e.stopPropagation();
          dismiss();
        }}
      />
    </Container>
  );
};

const COOKIE_KEY = 'info-strip';

const Container = styled.div`
  background: ${(props) => props.theme.colors.eb1};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  font-size: 16px;
  padding: 0 50px;
  text-align: center;

  :hover {
    cursor: pointer;
    background: #3c30ab;
  }

  .icon {
    position: absolute;
    right: 25px;
  }

  ${mediaScreen('xs', 'md')} {
    font-size: 13px;

    .icon {
      right: 15px;
    }
  }
`;

interface IInfoStrip {
  style?: CSSProperties;
  onClick: () => void;
}
